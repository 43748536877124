import React, { createContext, useContext, useEffect, useState } from 'react';

import axios from '../config/axios';
import { navigate } from 'gatsby';

interface AuthProviderProps {
	children?: JSX.Element | JSX.Element[];
}

interface AuthContext {
	user: number | null;
	role: string | null;
	signIn: (login: string, password: string) => Promise<boolean>;
	signOut: () => void;
	verifyUser: () => void;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);

	const verifyUser = () => {
		const token = localStorage.getItem('token');
		if (!token) {
			setUser(null);
			setRole(null);
			return;
		}
		(async () => {
			try {
				const response = await axios.get('/me.php');
				if (response.data.login) {
					setUser(response.data.login);
					setRole(response.data.role);
				} else {
					signOut();
				}
			} catch (_e) {
				signOut();
			}
		})();
	};

	useEffect(() => {
		verifyUser();
	}, []);

	const signIn = async (login: string, password: string): Promise<boolean> => {
		try {
			const response = await axios.post('/signin.php', { login, password });
			setUser(response.data.login);
			setRole(response.data.role);
			localStorage.setItem('token', response.data.token);
			switch (response.data.role) {
				case 'tender':
					navigate('/konto/przetargi/lista');
					break;
				case 'admin':
					navigate('/konto/przetargi/lista');
					break;
				default:
					navigate('/');
			}
		} catch (e) {
			const status = e.response?.status;
			if (status === 401) {
				return false;
			}
		}
		return true;
	};

	const signOut = async () => {
		try {
			setUser(null);
			setRole(null);
			const response = await axios.get('/signout.php');
		} catch (e) {
			console.log(e);
		} finally {
			localStorage.removeItem('token');
			navigate('/konto/logowanie/');
		}
	};

	return (
		<AuthContext.Provider value={{ user, role, signIn, signOut, verifyUser }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const auth = useContext(AuthContext);
	if (!auth) {
		throw new Error('useAuth needs to be used inside AuthContext');
	}
	return auth;
};
