exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-deklaracja-jsx": () => import("./../../../src/pages/deklaracja.jsx" /* webpackChunkName: "component---src-pages-deklaracja-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-formularz-tsx": () => import("./../../../src/pages/kontakt/formularz.tsx" /* webpackChunkName: "component---src-pages-kontakt-formularz-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-konto-formularz-tsx": () => import("./../../../src/pages/konto/formularz.tsx" /* webpackChunkName: "component---src-pages-konto-formularz-tsx" */),
  "component---src-pages-konto-grafik-tsx": () => import("./../../../src/pages/konto/grafik.tsx" /* webpackChunkName: "component---src-pages-konto-grafik-tsx" */),
  "component---src-pages-konto-informacje-tsx": () => import("./../../../src/pages/konto/informacje.tsx" /* webpackChunkName: "component---src-pages-konto-informacje-tsx" */),
  "component---src-pages-konto-jak-odzyskac-haslo-tsx": () => import("./../../../src/pages/konto/jak-odzyskac-haslo.tsx" /* webpackChunkName: "component---src-pages-konto-jak-odzyskac-haslo-tsx" */),
  "component---src-pages-konto-logowanie-tsx": () => import("./../../../src/pages/konto/logowanie.tsx" /* webpackChunkName: "component---src-pages-konto-logowanie-tsx" */),
  "component---src-pages-konto-przetargi-dodaj-tsx": () => import("./../../../src/pages/konto/przetargi/dodaj.tsx" /* webpackChunkName: "component---src-pages-konto-przetargi-dodaj-tsx" */),
  "component---src-pages-konto-przetargi-drukuj-tsx": () => import("./../../../src/pages/konto/przetargi/drukuj.tsx" /* webpackChunkName: "component---src-pages-konto-przetargi-drukuj-tsx" */),
  "component---src-pages-konto-przetargi-edytuj-tsx": () => import("./../../../src/pages/konto/przetargi/edytuj.tsx" /* webpackChunkName: "component---src-pages-konto-przetargi-edytuj-tsx" */),
  "component---src-pages-konto-przetargi-lista-tsx": () => import("./../../../src/pages/konto/przetargi/lista.tsx" /* webpackChunkName: "component---src-pages-konto-przetargi-lista-tsx" */),
  "component---src-pages-konto-przetargi-usun-tsx": () => import("./../../../src/pages/konto/przetargi/usun.tsx" /* webpackChunkName: "component---src-pages-konto-przetargi-usun-tsx" */),
  "component---src-pages-konto-wyloguj-tsx": () => import("./../../../src/pages/konto/wyloguj.tsx" /* webpackChunkName: "component---src-pages-konto-wyloguj-tsx" */),
  "component---src-pages-konto-zalogowany-tsx": () => import("./../../../src/pages/konto/zalogowany.tsx" /* webpackChunkName: "component---src-pages-konto-zalogowany-tsx" */),
  "component---src-pages-o-nas-120-lat-jsx": () => import("./../../../src/pages/o-nas/120-lat.jsx" /* webpackChunkName: "component---src-pages-o-nas-120-lat-jsx" */),
  "component---src-pages-o-nas-70-lat-jsx": () => import("./../../../src/pages/o-nas/70-lat.jsx" /* webpackChunkName: "component---src-pages-o-nas-70-lat-jsx" */),
  "component---src-pages-o-nas-autobusy-jsx": () => import("./../../../src/pages/o-nas/autobusy.jsx" /* webpackChunkName: "component---src-pages-o-nas-autobusy-jsx" */),
  "component---src-pages-o-nas-historia-jsx": () => import("./../../../src/pages/o-nas/historia.jsx" /* webpackChunkName: "component---src-pages-o-nas-historia-jsx" */),
  "component---src-pages-o-nas-historyczny-jsx": () => import("./../../../src/pages/o-nas/historyczny.jsx" /* webpackChunkName: "component---src-pages-o-nas-historyczny-jsx" */),
  "component---src-pages-o-nas-organizacja-jsx": () => import("./../../../src/pages/o-nas/organizacja.jsx" /* webpackChunkName: "component---src-pages-o-nas-organizacja-jsx" */),
  "component---src-pages-o-nas-przewozy-jsx": () => import("./../../../src/pages/o-nas/przewozy.jsx" /* webpackChunkName: "component---src-pages-o-nas-przewozy-jsx" */),
  "component---src-pages-o-nas-tramwaje-jsx": () => import("./../../../src/pages/o-nas/tramwaje.jsx" /* webpackChunkName: "component---src-pages-o-nas-tramwaje-jsx" */),
  "component---src-pages-ogloszenia-tsx": () => import("./../../../src/pages/ogloszenia.tsx" /* webpackChunkName: "component---src-pages-ogloszenia-tsx" */),
  "component---src-pages-policja-tsx": () => import("./../../../src/pages/policja.tsx" /* webpackChunkName: "component---src-pages-policja-tsx" */),
  "component---src-pages-praca-tsx": () => import("./../../../src/pages/praca.tsx" /* webpackChunkName: "component---src-pages-praca-tsx" */),
  "component---src-pages-przetargi-lista-tsx": () => import("./../../../src/pages/przetargi/lista.tsx" /* webpackChunkName: "component---src-pages-przetargi-lista-tsx" */),
  "component---src-pages-przetargi-pokaz-tsx": () => import("./../../../src/pages/przetargi/pokaz.tsx" /* webpackChunkName: "component---src-pages-przetargi-pokaz-tsx" */),
  "component---src-pages-uslugi-diagnostyka-jsx": () => import("./../../../src/pages/uslugi/diagnostyka.jsx" /* webpackChunkName: "component---src-pages-uslugi-diagnostyka-jsx" */),
  "component---src-pages-uslugi-pozostale-uslugi-jsx": () => import("./../../../src/pages/uslugi/pozostale-uslugi.jsx" /* webpackChunkName: "component---src-pages-uslugi-pozostale-uslugi-jsx" */),
  "component---src-pages-uslugi-reklama-jsx": () => import("./../../../src/pages/uslugi/reklama.jsx" /* webpackChunkName: "component---src-pages-uslugi-reklama-jsx" */),
  "component---src-pages-uslugi-wynajem-taboru-jsx": () => import("./../../../src/pages/uslugi/wynajem-taboru.jsx" /* webpackChunkName: "component---src-pages-uslugi-wynajem-taboru-jsx" */),
  "component---src-pages-uslugi-wynajem-taboru-specjalistycznego-jsx": () => import("./../../../src/pages/uslugi/wynajem-taboru-specjalistycznego.jsx" /* webpackChunkName: "component---src-pages-uslugi-wynajem-taboru-specjalistycznego-jsx" */),
  "component---src-pages-zdalnapomoc-tsx": () => import("./../../../src/pages/zdalnapomoc.tsx" /* webpackChunkName: "component---src-pages-zdalnapomoc-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blog-list-template.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */)
}

